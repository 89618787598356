<template>
	<div class="about">
		<headers></headers>
		<el-carousel class="bannerimgs" indicator-position="outside" height="600px">
			<el-carousel-item v-for="item in imglist" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<el-carousel class="phonebannerimgs" indicator-position="outside" height="200px">
			<el-carousel-item v-for="item in imglist2" :key="item">
				<img style="width: 100%;" :src="item" alt="">
			</el-carousel-item>
		</el-carousel>
		<!-- <div class="indeximg"><img src="../assets/img/about.jpg" alt=""></div> -->
		<!-- <div class="phoneindeximg"><img src="../assets/img/aboutphone.jpg" alt=""></div> -->
		<div class="cont">
			<div class="development">
				<div class="title">
					<p>发展历程</p>
					<p>Development History</p>
				</div>
				<div class="list">
					<div class="childertit childertits">
						<span><img src="../assets/img/abouttxt1.png"></span>
					</div>
					<p><i class="icones"></i>2006年注册成立“陕西金唐职业”。</br>
							<i class="icones"></i>成为全国第三家、西北首家《育婴师》职业资格培训项目执行机构。</p>
				</div>
				<div class="list">
					<div class="childertit">
						<span><img src="../assets/img/abouttxt2.png"></span>
					</div>
					<p><i class="icones"></i>成功开办《育婴师师资》《育婴师》职业资格认证班超过20期。</br>
							<i class="icones"></i>成功引进人社部《儿童健康教育指导师》《早期教育指导师》等项目，并完成认证班超过15期。</p>
				</div>
				<div class="list">
					<div class="childertit">
						<span><img src="../assets/img/abouttxt3.png"></span>
					</div>
					<p class="contts">
						<i class="icones"></i>创办金优学能儿童学习能力教育研究中心。</br>
						<i class="icones"></i>与安康学院、咸阳师范、陕学前、运城幼师、阳泉师专、晋中师专、宝鸡职业、延安职业、铜川职业、汉中职业等近20所院校开展学生技能培训合作。</br>
						<i class="icones"></i>收购新爱婴紫薇田园都市中心，并植入自有早教理念和课程。</br>
						<i class="icones"></i>参与多校人才培养方案研讨制定。</br>
						<i class="icones"></i>举办50多场教育类家长和教师讲座。</br>
						<i class="icones"></i>参与山西省国培项目授课。
					</p>
				</div>
				<div class="list">
					<div class="childertit">
						<span><img src="../assets/img/abouttxt4.png"></span>
					</div>
					<p><i class="icones"></i>创办“蒙洛西”托育品牌，并启动加盟。</br>
							<i class="icones"></i>创办“金智杯”早幼教人才技能大赛，并成功连续举行3届。</br>
							<i class="icones"></i>被评为陕西省著名商标、西安市著名商标、CCTV央视网推荐教育品牌单位。</br>
							<i class="icones"></i>举办早幼教行业讲座、活动100多场次。</br>
							<i class="icones"></i>举办多场学前专业简笔画、单项技能，以及最美护士等比赛。</br>
							<i class="icones"></i>旗下台湾伟童品牌幼儿园开园。</br>
							<i class="icones"></i>为多所本科、师专、高职院校提供一线技能课程入校园服务。</br>
							<i class="icones"></i>行业指导业务拓展至全国4省。</br>
							<i class="icones"></i>10多所高职、师专院校行业交流组织。</br>
							<i class="icones"></i>协办意大利国际奥尔夫音乐师资研修班。</br>
							<i class="icones"></i>协办第51届中国园长发展论坛。</br>
							<i class="icones"></i>协办西咸新区母婴行业校企论坛。</br>
							<i class="icones"></i>冠名并承办咸阳师范职业技术学院学前教育专业首届技能大赛。</br>
							<i class="icones"></i>与20多所高职、师专院校达成校企合作 。</br>
							<i class="icones"></i>受邀在中国幼教年会做主题分享。</br>
							<i class="icones"></i>作为优秀校企合作单位参加“陕西城镇建设职业教育集团2019年工作会议”。</br>
							<i class="icones"></i>承建多所高职院校共计20多间实训室。</p>
				</div>
				<div class="list">
					<div class="childertit">
						<span><img src="../assets/img/abouttxt5.png"></span>
					</div>
					<p><i class="icones"></i>成为多所本科、师专、高职院校深度校企合作单位和实践基地。</br>
							<i class="icones"></i>探索和制定有自主知识产权的“现代学徒制”“以赛代考”等创新模式。</br>
							<i class="icones"></i>疫情期间开办了家庭教育指导、教师技能培训的线上公益讲堂。</br>
							<i class="icones"></i>与西北工业学校联合开办幼儿保育专业。</br>
							<i class="icones"></i>与陕西艺术职业签订产学研训合作协议。</br>
							<i class="icones"></i>成功竞标西宁城市学院、安康职业、汉中职业、武威职业等院校的实训室建设项目。</br>
							<i class="icones"></i>完成幼儿园、早教、托育行业岗位调研。</br>
							<i class="icones"></i>业务拓展至全国7省。</p>
				</div>
				<div class="list">
					<div class="childertit childertits">
						<span><img src="../assets/img/abouttxt6.png"></span>
					</div>
					<p>更名为陕西墨智教育科技有限公司</p>
				</div>
			</div>
			<div class="culture">
				<div class="title">
					<p>企业文化</p>
					<p>Corporate Culture</p>
				</div>
				<div class="detail">
					<div class="list1">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon1.png">
							<div class="ftit">
								<p class="ty1">成立·立足</p>
								<p class="ty2">小信诚则大信立</p>
							</div>
						</div>
						<p class="ms">
							陕西墨智教育科技有限公司的前身为陕西金智教育咨询有限公司，成立于2006年，注册资金1680万，2022年4月更名为陕西墨智教育科技。本部位于陕西省西安市，是西北地区较早成长起来的面向高校、0-12岁儿童教育行业的综合性教育服务机构。
						</p>
					</div>
					<div class="list2">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon2.png">
							<div class="ftit">
								<p class="ty1">成长·拓展</p>
								<p class="ty2">信为根，诚为干，务实为养，有成长</p>
							</div>
						</div>
						<p class="ms">
							自2006年成立以来，墨智教育科技始终专一于早幼托教育行业。现已发展成为拥有多家幼儿园、早教中心、托育中心、感觉统合训练中心等儿童教育实体的综合性、集团性教育研发和服务机构。面向高校和早幼托机构，提供早幼托教育行业师资培养、一线教案研发和输出、行业和岗位调研、品牌加盟和技术指导、高校专业建设咨询、高校技能课程输出和双师培养、现代学徒服务和技能大赛、人才培养模式和教学创新改革设计、信息技术+教育设计和实训室规划建设等服务。
						</p>
					</div>
					<div class="list3">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon3.png">
							<div class="ftit">
								<p class="ty1">内涵·深耕</p>
								<p class="ty2">专于一业，静于一行；勤思，践行，而得精</p>
							</div>
						</div>
						<p class="ms">
							我们专一于早幼托行业16年，现已积累了多门一线课程教材、10多项版权成果、3大行业品牌，以及行业和高校的良好口碑。
						</p>
					</div>
					<div class="list4">
						<div class="aboutfbt">
							<img class="imgs" src="../assets/img/abouticon4.png">
							<div class="ftit">
								<p class="ty1">成就·发展</p>
								<p class="ty2">诚信务实，专业如一，勤思善行，得获而不骄，故恒远</p>
							</div>
						</div>
						<p class="ms">
							2019年起，公司紧跟行业发展、洞悉职业教育改革方向，将内涵建设、墨守诚信和创新发展确定为下一个五年期的发展核心，将行业核心应用转化为高校职业教育课程和实训评价，为职业教育改革注入新活力，力争在产品项目、客户服务、技术创新等方面再创新高！
						</p>
					</div>
				</div>
			</div>
		</div>
		<formdata></formdata>
		<footers></footers>
	</div>
</template>

<script>
	import headers from '../components/header.vue'
	import footers from '../components/footer.vue'
	import formdata from '../components/forsubmit.vue'
	export default {
		components: {
			headers,
			footers,
			formdata
		},
		data() {
			return {
				imglist: [
					require('../assets/img/c8644a2.png')
				],
				imglist2: [
					require('../assets/img/90b38e98fa91855976d30ef2b935fdf.png')
				],
			}
		},
		watch: {

		},
		created() {
			this.querySortImg()
		},
		mounted() {

		},
		methods: {
			querySortImg() {
				let gh = []
				this.$post('querySortImg', {
					sortHead: 2,
					sortOrder: 0,
					sortFrom: 0
				}).then((res) => {
					if (res&&res.length>0) {
						this.imglist = []
						this.imglist2 = []
						res.forEach((e, m) => {
							let h = e.sortImg.split(',')
							h.forEach((v, b) => {
								if (e.sortSign == 0) {
									this.imglist.push(v)
								} else {
									gh.push(v)
								}
							})
						})
						if (!gh || gh.length <= 0) {
							this.imglist.forEach((f, g) => {
								f = f + '?x-bce-process=image/crop,x_500,y_200,w_701,h_400'
								this.imglist2.push(f)
							})
						}else{
							this.imglist2 = gh
						}
					} else {

					}
				})
				this.$nextTick(() => {

				})
			}
		}
	}
</script>

<style scoped lang="less">
	.about {
		.phonebannerimgs{
			display: none;
		}
		.cont {
			width: 1200px;
			font-size: 0;
			margin: 0 auto;

			>* {}

			.title {
				text-align: center;
				font-size: 0;

				>:first-child {
					font-size: 26px;
					margin: 0 0 6px 0;
					color: #f49952;
				}

				>:last-child {
					font-size: 18px;
					color: #f7c59f;
				}

			}

			.childertit {
				position: relative;
				display: inline-block;
				padding: 0 30px 0 50px;
				margin: 0 0 40px 0;

				>span {
					display: inline-block;
					width: 200px;
					font-size: 0;

					>img {
						width: 100%;
					}
				}

				>span::after {
					content: '';
					width: 4px;
					height: 20px;
					position: absolute;
					top: 13px;
					left: 5px;
					background-color: #f5974f;
					transform: rotate(30deg);
				}

				>span::before {
					content: '';
					width: 4px;
					height: 30px;
					position: absolute;
					top: 5px;
					left: 20px;
					background-color: #f5974f;
					transform: rotate(30deg);
				}
			}

			.childertit::after {
				content: '';
				width: 100%;
				height: 4px;
				position: absolute;
				top: 30px;
				left: 0;
				background-color: #f5974f;
			}

			.childertits {
				padding: 0 10px 0 45px;

				>span {
					width: 110px;
				}
			}

			.development {
				margin: 80px 0 0 0;

				.list {
					margin-bottom: 50px;

					>p {
						letter-spacing: 1px;
						// text-indent: 40px;
						line-height: 35px;
						font-size: 18px;
						color: #333;
					}
					>p.contts{
						letter-spacing: 0;
						text-indent: 0;
					}
					.icones {
						width: 5px;
						height: 5px;
						font-size: 0;
						background-color: #333;
						border-radius: 100px;
						display: inline-block;
						vertical-align: middle;
						margin: 0 7px 0 0;
					}
				}
			}

			.culture {
				margin: 80px 0;

				.detail {
					margin: 40px 0 0 0;
					text-align: left;

					.aboutfbt {
						position: relative;

						>* {
							display: inline-block;
							vertical-align: middle;
						}

						.imgs {
							margin: 0 20px 0 0;
						}

						.ftit {
							.ty1 {
								font-size: 16px;
								color: #333;
								margin: 0 0 10px 0;
								letter-spacing: 3px
							}

							.ty2 {
								font-size: 14px;
								color: #666;
								letter-spacing: 4px
							}
						}

						.ftit::after {
							content: '';
							width: 17px;
							height: 17px;
							position: absolute;
							top: 3px;
							left: 16%;
							background-color: #e69c3b;
						}

						.ftit::before {
							content: '';
							width: 17px;
							height: 17px;
							position: absolute;
							top: 3px;
							left: 18%;
							background-color: #6fa0d4;
						}
					}

					.aboutfbt::after {
						content: '';
						width: 17px;
						height: 17px;
						position: absolute;
						top: 3px;
						left: 14%;
						background-color: #74c5cc;
					}

					.ms {
						font-size: 18px;
						color: #000;
						line-height: 30px;
						margin: 25px 0 0 0;
						letter-spacing: 2px;
						text-indent: 40px;
					}

					.list1,
					.list2,
					.list3 {
						margin: 0 0 60px 0;
					}
				}
			}
		}
	}

	@media screen and (max-width:900px) {
		.about {

			.bannerimgs {
				display: none;
			}

			.phonebannerimgs {
				display: block;
				margin-top: 64px;
			}

			.cont {
				width: 97%;
				font-size: 0;
				margin: 0 auto;

				>* {}

				.title {

					>:first-child {
						font-size: 20px;
						margin: 0 0 6px 0;
					}

					>:last-child {
						font-size: 14px;
					}

				}

				.childertit {
					position: relative;
					display: inline-block;
					padding: 0 30px 0 50px;
					margin: 0 0 40px 0;

					>span {
						display: inline-block;
						width: 140px;
						font-size: 0;

						>img {
							width: 100%;
						}
					}

					>span::after {
						content: '';
						width: 4px;
						height: 20px;
						position: absolute;
						top: 9px;
						left: 4px;
						background-color: #f5974f;
						transform: rotate(30deg);
					}

					>span::before {
						content: '';
						width: 4px;
						height: 30px;
						position: absolute;
						top: 0px;
						left: 20px;
						background-color: #f5974f;
						transform: rotate(30deg);
					}
				}

				.childertit::after {
					content: '';
					width: 100%;
					height: 4px;
					position: absolute;
					top: 25px;
					left: 0;
					background-color: #f5974f;
				}

				.childertits {
					padding: 0 10px 0 45px;

					>span {
						width: 80px;
					}
				}

				.development {
					margin: 40px 0 0 0;

					.list {
						margin: 20px 0;

						>p {
							// text-indent: 30px;
							line-height: 25px;
							font-size: 16px;
						}
					}
				}

				.culture {
					margin: 40px 0;

					.detail {

						.aboutfbt {
							.imgs {
								width: 10%;
								margin: 0 10px;
							}

							.ftit {
								width: 80%;

								.ty1 {
									font-size: 14px;
									margin: 0 0 10px 0;
									letter-spacing: 2px
								}

								.ty2 {
									font-size: 12px;
									letter-spacing: 3px
								}
							}

							.ftit::after {
								width: 10px;
								height: 10px;
								top: 5px;
								left: 41%;
							}

							.ftit::before {
								width: 10px;
								height: 10px;
								top: 5px;
								left: 45%;
							}
						}

						.aboutfbt::after {
							width: 10px;
							height: 10px;
							top: 5px;
							left: 37%;
						}

						.ms {
							text-indent: 30px;
							font-size: 14px;
							line-height: 25px;
							margin: 15px 0 0 0;
							letter-spacing: 1px
						}

						.list1,
						.list2,
						.list3 {
							margin: 0 0 60px 0;
						}
					}
				}
			}
		}
	}
</style>
